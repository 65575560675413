<template>
  <v-list multiple>
    <v-list-item v-for="item in lastChanges" :key="item.id">
      <v-list-item-content>
        <v-list-item-title>Version {{ item.version }}</v-list-item-title>
        <v-list-item-subtitle>
          <ul>
            <li v-for="(change, index) in item.changes" :key="index">
              {{ change }}
            </li>
          </ul>
        </v-list-item-subtitle>
        <v-list-item-subtitle
          >Veröffentlicht am {{ item.date }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-btn icon>
          <!--<v-icon disabled>fal fa-search</v-icon>-->
        </v-btn>
      </v-list-item-action>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: "Changelog",
  components: {},
  computed: {
    lastChanges() {
      const versions = this.versions;
      return versions.slice(0, 5);
    }
  },
  data() {
    return {
      versions: [
        {
          version: "1.32",
          changes: [
            "Update der Systembibliotheken auf aktuelle Versionen",
            "Kleinere Fehler behoben"
          ],
          date: "20.01.25"
        },

        {
          version: "1.31",
          changes: [
            "Vorbereitungen für die Rechnungen",
            "Anpassungen beim Import von Zahlungen"
          ],
          date: "10.10.24"
        },
        {
          version: "1.30",
          changes: [
            "Label Familie/Kinder/Erwachsene wurde entfernt",
            "Erinnergungsmail für Clubmitglieder werden nicht mehr bei vorhandenem Guthaben versendet"
          ],
          date: "26.02.24"
        },
        {
          version: "1.26",
          changes: [
            "Kursteilnehmer können über eine einheitliche Nummer per SMS angeschrieben werden.",
            "Fehler beim aufbereiten der Handynummer behoben",
            "Datenexport für Kurse hinzugefügt"
          ],
          date: "31.10.23"
        },
        {
          version: "1.25",
          changes: [
            "Eine individuelle Danke Seite kann in den Einstellungen hinterlegt werden."
          ],
          date: "10.10.23"
        },
        {
          version: "1.24",
          changes: ["Export von Kunden-, Teilnehmer- und Buchungsdaten"],
          date: "14.09.23"
        },
        {
          version: "1.23",
          changes: [
            "Anpassen der Bestätigungsmails",
            "Einmalige Terminbuchungen erscheinen jetzt nicht mehr in der Fehlerliste"
          ],
          date: "21.08.23"
        },
        {
          version: "1.22",
          changes: [
            "Erweiterung der Standorteinstellungen",
            "Hinzufügen von Rechnungsnummern zur Vorbereitung des Rechnungsexports"
          ],
          date: "25.07.23"
        },
        {
          version: "1.21.0",
          changes: [
            "Zahlungen können jetzt nach dem Import Datum exportiert werden",
            "Fehler beim Speichern des Hinweises zum Teilnehmer bei der Buchung behoben"
          ],
          date: "02.06.23"
        },
        {
          version: "1.20.0",
          changes: [
            "Erweiterung der Validierung bei Kursvorlagen",
            "Fehler beim löschen von Kursvorlagen behoben"
          ],
          date: "29.03.23"
        },
        {
          version: "1.19.0",
          changes: [
            "Bäder können gelöscht werden",
            "Bahnen können erstellt, überarbeitet und gelöscht werden"
          ],
          date: "24.03.23"
        },
        {
          version: "1.18.0",
          changes: [
            "Kurse mit vergangenen Terminen werden nicht mehr in der Übersicht angezeigt",
            "Fehler beim Import von Zahlungen behoben",
            "Dauer bis zum automatischen Logout auf 9 Stunden erhöht",
            "Neue Kalenderansicht unter Buchungen"
          ],
          date: "18.03.23"
        },
        {
          version: "1.17.0",
          changes: [
            "Fehler beim Import von Zahlungen behoben",
            "Benutzerverwaltung optimieren",
            "Kleinere Fehler behoben"
          ],
          date: "13.03.23"
        },
        {
          version: "1.16.0",
          changes: [
            "PayPal in den Standorteinstellungen ergänzt",
            "Link zur externen Buchunsseite hinterlegt",
            "Changelog überarbeitet"
          ],
          date: "10.03.23"
        },
        {
          version: "1.15.0",
          changes: ["Einstellungen zum Standort erweitert"],
          date: "16.02.23"
        },
        {
          version: "1.14.0",
          changes: [
            "Neues Changelog im Dashboard eingefügt",
            "Fehler in der Zahlungsübersicht behoben"
          ],
          date: "16.02.23"
        }
      ]
    };
  }
};
</script>
